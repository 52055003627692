<template>
  <a-spin :spinning="loading">
    <a-form-model ref="form" :colon="false" :model="form">
      <a-descriptions
        ref="des"
        id="DiscountPresetTable"
        class="DiscountPresetTable"
        bordered
        layout="vertical"
        :column="form.data.length + 1"
        size="small"
      >
        <a-descriptions-item :label="$t('PlineDetails.match_range')">{{
          $t('PlineDetails.discount_ratio')
        }}</a-descriptions-item>
        <template v-if="edit">
          <a-descriptions-item v-for="(item, index) in form.data" :key="index + 1" :label="item.discountRange">
            <div class="_point editItem" v-if="!item.editable" @click="edits(item)">{{ item.discountValue }}</div>
            <a-form-model-item
              v-show="item.editable"
              style="margin-bottom: 0px"
              class="table-item"
              :prop="`data.${index}.discountValue`"
              :rules="[validate.required, decimal_0_1]"
            >
              <a-input class="_w-pct-100" @blur="save(item)" @pressEnter="save(item)" v-model="item.discountValue" />
            </a-form-model-item>
          </a-descriptions-item>
        </template>
        <template v-else>
          <a-descriptions-item v-for="(item, index) in form.data" :key="index + 1" :label="item.discountRange">
            {{ item.discountValue }}
          </a-descriptions-item>
        </template>
      </a-descriptions>
    </a-form-model>
  </a-spin>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import discountList from './discountList.js'
import debounce from 'lodash/debounce'
export default {
  name: 'DiscountPresetTable',
  props: {
    datap: {
      type: Array,
      default: () => [],
    },
    edit: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      decimal_0_1: { pattern: /^(0\.(0[1-9]|[1-9]{1,2}|[1-9]0)$)|^[0-1]$/, message: this.$t('validate.decimal_0_1') },
      validate: window.$g.validate,
      form: {
        data: [],
      },
      loading: true,
      datao: [],
      formData: [],
    }
  },
  watch: {
    datap: {
      deep: true,
      handler(arr) {
        arr?.length > 0 && this.init()
      },
    },
  },
  mounted() {
    this.info?.id && this.getBaseInfo()
  },
  computed: {
    discountRate() {
      return this.$store.state.app.discountRate
    },
  },
  methods: {
    getBaseInfo: debounce(async function () {
      try {
        this.loading = true
        const data = await this.$http({ key: 'supEntityInfo', params: { id: this.info.id } })
        this.datao = data.discountList
        this.formData = data
        this.init()
      } catch (error) {
        this.loading = false
        this.$httpNotify(error)
      }
    }, 200),
    init() {
      let _data = []
      if (this.datao.length > 0) {
        _data = this.datao
      } else if (this.datap.length > 0) {
        _data = this.datap
      } else {
        console.error('意外')
      }
      const data = _data.map((ele) => {
        ele.editable = false
        return ele
      })
      this.form.data = JSON.parse(JSON.stringify(data))
      this.loading = false
    },
    edits(item) {
      // 打开当前列编辑，关闭其他列编辑
      this.closeAllEdit('edits', item)
      item.editable = true
    },
    validates(value) {
      return /^(0\.(0[1-9]|[1-9]{1,2}|[1-9]0)$)|^[0-1]$/.test(value)
    },
    closeAllEdit(type, item) {
      let allOk = true
      this.form.data.forEach((_item) => {
        if (this.validates(_item.discountValue)) {
          _item.editable = false
        } else {
          console.error('不符合正则匹配')
          allOk = false
        }
      })
      if (type === 'save' && allOk) {
        this.submit(item)
      }
    },
    submit: debounce(async function (item) {
      console.warn('submit.....')
      try {
        const params = item
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('saveSupDiscount', params)
        this.$message.success(this.$t('customerConfig.edit_succ'))
      } catch (err) {
        this.$httpNotify(err)
      }
    }, 100),
    // eslint-disable-next-line no-unused-vars
    save(item) {
      this.closeAllEdit('save', item)
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep {
  th.ant-descriptions-item-label.ant-descriptions-item-colon {
    width: 9.1%;
  }
}
</style>
