<template>
  <a-spin :spinning="loading">
    <a-form-model ref="form" :colon="false" :model="form">
      <a-table
        class="price-list-table head-bg no-br"
        size="middle"
        rowKey="id"
        bordered
        :pagination="pricePagination"
        :data-source="form.priceInfo"
        :loading="priceTableLoading"
      >
        <a-table-column key="serviceId" :title="$t('priceList.service_type')" :width="width">
          <template slot-scope="record">
            <template v-if="record.editable">
              <a-select v-model="record.serviceId">
                <a-select-option v-for="(item, index) in serviceType" :key="index" :value="item.id">{{
                  item.label
                }}</a-select-option>
              </a-select>
            </template>
            <template v-else>
              {{ record.serviceLabel | defined }}
            </template>
          </template>
        </a-table-column>

        <a-table-column key="documentId" :title="$t('priceList.doc_type')" :width="width">
          <template slot-scope="record">
            <template v-if="record.editable">
              <a-select v-model="record.documentId" :placeholder="$t('priceList.doc_type_holder')">
                <a-select-option v-for="(item, index) in docType" :key="index" :value="item.id">{{
                  item.label
                }}</a-select-option>
              </a-select>
            </template>
            <template v-else>
              {{ record.documentLabel | defined }}
            </template>
          </template>
        </a-table-column>
        <a-table-column key="sourceCode" :title="$t('priceList.source_language')" :width="width">
          <template slot-scope="record">
            <template v-if="record.editable">
              <a-form-model-item
                style="margin-bottom: 0px"
                :validate-status="getValidateStatus(record.sourceCode, record.targetCode, record.sourceCode)"
                :help="getValidateTip(record.sourceCode, record.targetCode, record.sourceCode)"
              >
                <a-select
                  show-search
                  v-model="record.sourceCode"
                  :placeholder="$t('priceList.source_language_holder')"
                  :filter-option="filterOption"
                >
                  <a-select-option
                    v-for="(item, index) in langList"
                    :key="index"
                    :value="item.code"
                    :disabled="item.code === record.targetCode"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
            </template>
            <template v-else>
              {{ getLanguage(record.sourceCode) | defined }}
            </template>
          </template>
        </a-table-column>
        <a-table-column key="targetCode" :title="$t('priceList.target_language')" :width="width">
          <template slot-scope="record">
            <template v-if="record.editable">
              <a-form-model-item
                style="margin-bottom: 0px"
                :validate-status="getValidateStatus(record.sourceCode, record.targetCode, record.targetCode)"
                :help="getValidateTip(record.sourceCode, record.targetCode, record.targetCode)"
              >
                <a-select
                  show-search
                  v-model="record.targetCode"
                  :placeholder="$t('priceList.source_language_holder')"
                  :filter-option="filterOption"
                >
                  <a-select-option
                    v-for="(item, index) in langList"
                    :key="index"
                    :value="item.code"
                    :disabled="item.code === record.sourceCode"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
            </template>
            <template v-else>
              {{ getLanguage(record.targetCode) | defined }}
            </template>
          </template>
        </a-table-column>
        <a-table-column key="price" :title="$t('priceList.price')" :width="width">
          <template slot-scope="record">
            <template v-if="record.editable">
              <a-form-model-item
                style="margin-bottom: 0px"
                class="table-item"
                :prop="`priceInfo.${record.key}.price`"
                :rules="[validate.required, validate.float_3]"
              >
                <a-input v-model="record.price" :placeholder="$t('priceList.price_holder')"></a-input>
              </a-form-model-item>
            </template>
            <template v-else>
              {{ record.price | defined }}
            </template>
          </template>
        </a-table-column>
        <a-table-column key="unit" :title="$t('priceList.unit')" :width="width">
          <template slot-scope="record">
            <template v-if="record.editable">
              <a-select v-model="record.unit" :placeholder="$t('priceList.unit_holder')">
                <a-select-option v-for="(item, index) in unit" :key="index" :value="item.dictValue">{{
                  item.dictLabel
                }}</a-select-option>
              </a-select>
            </template>
            <template v-else>
              {{ getUnit(record.unit) | defined }}
            </template>
          </template>
        </a-table-column>

        <a-table-column v-if="showAction" :title="$t('companyInfo.action')" key="action" :width="width">
          <template slot-scope="record">
            <div class="editable-row-operations">
              <span v-show="record.editable">
                <a @click="handleSave(record)">{{ $t('save') }}</a>
              </span>
              <span v-show="!record.editable">
                <a
                  :disabled="canClickKey !== null && canClickKey !== record.key ? true : false"
                  @click="handleEdit(record)"
                  >{{ $t('edit') }}</a
                >
              </span>
              <a-popconfirm
                v-show="!record.editable"
                class="_ml-10"
                :title="$t('customerConfig.sure_del')"
                @confirm="del(record)"
              >
                <a :disabled="canClickKey !== null && canClickKey !== record.key ? true : false">{{ $t('delete') }}</a>
              </a-popconfirm>
            </div>
          </template>
        </a-table-column>
      </a-table>
    </a-form-model>
  </a-spin>
</template>

<script>
import { mapState } from 'vuex'
const { validate } = window.$g
// eslint-disable-next-line no-unused-vars
import debounce from 'lodash/debounce'

export default {
  name: 'PriceListTable',
  props: {
    showAction: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    isRefresh: {
      type: Boolean,
      default: false,
    },
    entityId: {
      type: String,
      default: '',
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        priceInfo: [],
      },
      selected: false,
      priceTableLoading: false,
      pricePagination: {
        current: 1,
        size: 'middle',
        total: 0,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '50'],
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) => {
          this.pricePagination.current = 1
          this.pricePagination.pageSize = pageSize
          this.getPriceList()
        },
        onChange: (page) => {
          this.pricePagination.current = page
          this.getPriceList()
        },
      },
      serviceType: [],
      docType: [],
      validate,
      loading: false,
      canClickKey: null,
    }
  },
  watch: {
    info: {
      deep: true,
      handler(v) {
        console.log(`v`, v)
        this.fetchAll()
      },
    },
  },
  computed: {
    ...mapState('app', {
      langList: (state) => state.langList,
      industryList: (state) => state.industryList,
    }),
    unit() {
      return this.$store.getters['app/getDictByType']('SALE_UNIT')
    },
    width() {
      return this.showAction ? `${(1 / 7) * 100}%` : `${(1 / 6) * 100}%`
    },
    agencyId() {
      return this.$store.state.app.userInfo.agencyId
    },
  },
  mounted() {
    this.fetchAll()
  },
  methods: {
    resetPage() {
      this.pricePagination.current = 1
      this.pricePagination.pageSize = 10
    },
    fetchAll() {
      this.resetPage()
      this.info.id && this.getPriceList()
      this.showAction && this.getCusServiceTypeList()
      this.showAction && this.getCusDocumentTypeList()
    },
    // 客户服务类型表-列表
    getCusServiceTypeList: debounce(
      async function () {
        try {
          const data = await this.$http('cusMaterialList', { entityId: this.agencyId, type: 1 })
          console.log(`serviceType`, data)
          this.serviceType = data
        } catch (err) {
          console.error(`err`, err)
          this.$httpNotify(err)
        }
      },
      200,
      { leading: true, trailing: false }
    ),
    // 客户文档类型表-列表
    getCusDocumentTypeList: debounce(
      async function () {
        try {
          const data = await this.$http('cusMaterialList', { entityId: this.agencyId, type: 2 })
          console.log(`docType`, data)
          this.docType = data
        } catch (err) {
          this.$httpNotify(err)
        }
      },
      200,
      { leading: true, trailing: false }
    ),
    // 筛选过滤
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleChange(value, record, key) {
      console.log({ value, record, key })
      record[key] = value
    },
    handleEdit(record) {
      console.log(`record`, record)
      if (this.canClickKey === null) {
        record.editable = true
        this.canClickKey = record.key
      } else if (this.canClickKey === record.key) {
        record.editable = true
      } else {
        console.log('请先保存完正在编辑的数据项！')
      }
    },
    getValidateStatus(sourceCode, targetCode, code) {
      const error = !!sourceCode !== !!targetCode && !code
      if (error) {
        return 'error'
      } else {
        return ''
      }
    },
    getValidateTip(sourceCode, targetCode, code) {
      console.log({ sourceCode, targetCode, code })
      const error = !!sourceCode !== !!targetCode && !code
      console.log('error :>> ', error)
      if (error) {
        return this.$t('validate.required')
      } else {
        return ''
      }
    },
    langPairNoSingle() {
      let result = true

      this.form.priceInfo.forEach((_) => {
        if (!(!!_.sourceCode === !!_.targetCode)) {
          result = false
        }
      })

      return result
    },
    handleSave(record) {
      const { price } = record
      const check = (val) => {
        // console.log(`val`, val)
        // console.log(`this.$g.noEmpty(val)`, this.$g.noEmpty(val))
        // console.log(`validate.float_3.pattern.test(val)`, /^\d+(\.\d{1,3})?$/g.test(val))
        return this.$g.noEmpty(val) && /^\d+(\.\d{1,3})?$/g.test(val)
      }
      const langPairNoSingle = this.langPairNoSingle()
      console.log('langPairNoSingle :>> ', langPairNoSingle)
      if (langPairNoSingle && check(price)) {
        this.editedSave(record)
      } else {
        console.error('不符合正则校验')
      }
    },
    async editedSave(record) {
      this.loading = true
      try {
        const params = {
          entityId: this.info.id,
          id: record.id,
          price: record.price,
          unit: record.unit,
          serviceId: record.serviceId,
          documentId: record.documentId,
          targetCode: record.targetCode,
          sourceCode: record.sourceCode,
          supTenantCode: this.info.supTenantCode,
        }
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('supPriceUpdate', params)
        this.loading = false
        this.$message.success(this.$t('customerConfig.edit_succ'))
        this.getPriceList()
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },
    async del({ id }) {
      try {
        await this.$http('supPriceDelete', [id])
        this.$message.success(this.$t('customerConfig.del_succ'))
        this.getPriceList()
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    getServiceType(val) {
      return this.$store.getters['app/getDictLabel']('SERVICE_TYPE', val)
    },
    getDocType(val) {
      return this.$store.getters['app/getDictLabel']('DOC_TYPE', val)
    },
    getLanguage(val) {
      const data = this.$store.getters['app/getLangNameByCode'](val)
      return val === '' ? '---' : data
    },
    getUnit(val) {
      return this.$store.getters['app/getDictLabel']('SALE_UNIT', val)
    },
    // 根据客户实体获取服务类型价格
    getPriceList: debounce(async function () {
      try {
        const params = {
          limit: this.pricePagination.pageSize,
          page: this.pricePagination.current,
          entityId: this.info.id,
        }
        console.log(`params`, params)
        this.priceTableLoading = true
        const data = await this.$http('supPriceList', params)
        console.warn(`data---`, data)
        this.priceTableLoading = false
        data.list.forEach((item, index) => {
          item.key = index
          item.editable = false
        })
        this.canClickKey = null
        this.form.priceInfo = data.list
        this.pricePagination.total = data.total
      } catch (err) {
        this.priceTableLoading = false
        this.$httpNotify(err)
      }
    }, 200),
  },
}
</script>

<style lang="less" scoped>
.price-list-table {
  ::v-deep {
    .ant-table-body {
      tr > th:first-child {
        padding-left: 60px !important;
      }

      .ant-table-tbody {
        & > tr > td:first-child {
          padding-left: 60px !important;
        }
      }
    }
  }
}
</style>
